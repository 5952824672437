<template>
  <div>
    <user-list-top-nav />

    <div>
      <v-data-table
        :items="allUsersList"
        :pagination.sync="pagination"
        :loading="isLoading" 
        :total-items="total"
        :rows-per-page-items="[10, 25, 50, 100]"
        class="elevation-1"
        :headers-length="5"
      >
        <template slot="items" slot-scope="props">
          <td>
            <router-link
              :to="`/moderation/user_list/user/${props.item.id}`"
              class="blue-grey--text bold link-hover"
            >
              {{ props.item.username }}
            </router-link>
          </td>
          <td class="text-xs-left mail break">
            {{ props.item.email }}

            <span v-if="props.item.isNew" class="red--text ml-3 bold">New!
            </span>
          </td>
          <td class="text-xs-left">
            Karma: {{ props.item.karma }}
          </td>
          <td class="text-xs-left">
            Registration date: {{ dateFormat(props.item.registration_date) }}
          </td>
          <td class="text-xs-left">
            Balance:
            <span
              :class="[
                props.item.balance > 0 ? 'green-text' : 'red-text',
                'fw-600'
              ]"
            >
              {{ props.item.balance }}
            </span>
          </td>
          <td
            v-if="getUserInfoAndPermissions.role === 'Admin'"
            class="text-xs-right"
          >
            <v-btn
              v-if="!props.item.banned"
              color="error"
              class="ma-0"
              @click="openBlockUserModal(props.item.id)"
            >
              Block user
            </v-btn>

            <v-btn
              v-else
              color="success"
              :loading="props.item.id === isLoadingUnlock"
              @click.native="unlockedUser(props.item.id)"
            >
              Unlocked user
            </v-btn>
          </td>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          @input="refreshPagination"
        />
      </div>

      <block-user-modal
        v-model="blockUserModal"
        :user-id="userId"
        :reason="reason"
        @deleteCurrentUser="deleteCurrentUser"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex"
import {types} from "../../../../../store/modules/counterNew"
import { fetchData } from "../../../../../utils/response"
import { getModelUsersRequest } from "../../../../../api/users"
import { userUnbanned } from "../../../../../api/user"
import { getReasons } from "../../../../../api/reason"
import moment from "moment"
import SavesPagination from "../../../../../mixins/SavesPaginationMixin"
import UserListTopNav from "../UserListTopNav"
import BlockUserModal from "../../../../../components/Moderation/UserList/BlockUserModal"

export default {
  name: "AllUsersList",
  components: {
    UserListTopNav,
    BlockUserModal
  },
  mixins: [SavesPagination],
  data() {
    return {
      blockUserModal: false,
      userId: null,
      deletedUser: [],
      reason: [],
      dialog: false,
      editedIndex: -1,
      pagination: {},
      isLoadingUnlock: null,
      isLoading: false,
      total: 0,
      allUsersList: [],
      select: null,
      search: null,
      blockUserId: null
    }
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"]),
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    this.getReasonBlockUser()
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  created() {
    this.$bus.on("user-sort-by", this.onSelectUsersBy)
    this.$bus.on("user-search-input", this.onSearchUser)
  },
  destroyed() {
    this.search = null
    this.$bus.off("user-sort-by", this.onSelectUsersBy)
    this.$bus.off("user-search-input", this.onSearchUser)
  },
  methods: {
    ...mapMutations({setNewModel: types.SET_NEW_MODEL}),
    openBlockUserModal(id) {
      this.userId = id
      this.blockUserModal = true
    },
    async getReasonBlockUser() {
      this.reason = await getReasons()
        .then(response => {
          return response.data.data
        })
        .then(data =>
          data.map(reason => {
            return reason
          })
        )
        .catch(this.$notificationHandleResponseError)
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        order_by: this.select,
        search: this.search
      }
      this.isLoading = true
      this.allUsersList = await getModelUsersRequest(params)
        .then(response => {
          this.total = response.data.meta.total
          this.setNewModel(response.data.additional.new_users_count)
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(user => {
            return user
          })
        )
        .catch(this.$notificationHandleResponseError)

      this.isLoading = false
    },
    async deleteCurrentUser(id) {
      // this.deletedUser.push(id);
      let index = await this.allUsersList
        .map(item => {
          return item.id
        })
        .indexOf(id)
      await this.allUsersList.splice(index, 1)
      this.$notificationShow("The user is successfully blocked")
      // this.allUsersList[index].banned = true;
    },
    unlockedUser(id) {
      this.isLoadingUnlock = id
      userUnbanned(id)
        .then(() => {
          let index = this.allUsersList
            .map(item => {
              return item.id
            })
            .indexOf(id)
          this.allUsersList[index].banned = false
          // this.deletedUser.includes(id)
          //   ? this.deletedUser.splice(this.deletedUser.indexOf(id), 1)
          //   : false;
          this.$notificationShow("The user is successfully unlocked")
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoadingUnlock = null))
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    onSelectUsersBy(sortUserSelectValue) {
      this.select = sortUserSelectValue
      this.refresh()
    },
    onSearchUser(searchUserValue) {
      this.search = searchUserValue
      this.refresh()
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  },
}
</script>
<style scoped>
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.mail{
  max-width: 832px;
}
</style>
